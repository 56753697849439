import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import cn from 'classnames';

import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Dialog from '~components/Dialog';
import LoadingSpinner from '~components/LoadingSpinner';
import ButtonClosePopup from '../components/ButtonClosePopup';

const useStyles = makeStyles(theme => ({
  dialogStyle: {
    '& > div > .MuiPaper-root': {
      backgroundColor: 'transparent'
    },
    [theme.breakpoints.down('xs')]: {
      '& > div > .MuiDialog-paperWidthSm': {
        maxWidth: 'calc(100% - 24px) '
      }
    }
  },
  closeButtonStyle: {
    [theme.breakpoints.down('xs')]: {
      right: '10px'
    },
    flexShrink: '0',
    position: 'absolute',
    top: '16px',
    right: '-45px',
    padding: 0,
    marginTop: 0,
    borderRadius: '0px 15.966px 15.966px 0px'
  },
  imageStyle: {
    [theme.breakpoints.down('xs')]: {
      height: '50%',
      width: '100%'
    },
    height: '100%',
    width: '50%'
  },
  boxStyle: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      top: '-14%',
      right: '8%',
      flexShrink: '0',
      width: '308px',
      height: 'auto',
      flexDirection: 'column'
    },
    backgroundColor: 'white',
    borderRadius: '10px',
    boxSizing: 'border-box',
    overflow: 'clip',
    border: 'none ',
    display: 'flex',
    flexDirection: 'row'
  },
  gridStyle: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingTop: '0px',
      padding: '28px'
    },
    width: '50%',
    padding: '38px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  subtitleStyle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      padding: '0px',
      height: 'fit-content'
    },
    marginTop: '13px',
    marginBottom: '30px',
    display: 'flex',
    flexDirection: 'column',
    color: '#5C6173',
    fontSize: '1rem',
    fontFamily: 'Inter',
    lineHeight: '150%'
  },
  errorText: {
    color: '#FF4D3D',
    fontSize: 14,
    lineHeight: '140%',
    width: '100%'
  },
  inputContainerStyle: {
    [theme.breakpoints.up('sm')]: {
      bottom: '2.5em',
      height: '3.125rem'
    },
    padding: '0px',
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #000000',
    borderRight: 'none',
    borderRadius: '6px',
    boxSizing: 'border-box',
    height: '44px',
    position: 'inline-block',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '6px',
    paddingBottom: '0px'
  },
  inputContainerErrorStyle: {
    border: '1px solid #FF4D3D !important'
  },
  emailInputRootStyle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      height: '44px'
    },
    width: '10rem',
    fontSize: '1rem',
    height: '3.125rem',
    backgroundColor: 'transparent',
    marginLeft: 10
  },
  titleStyle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
      height: 'fit-content',
      marginTop: '24px',
      padding: '0px',
      textAlign: 'left'
    },
    color: '#5B6173',
    fontSize: '2rem',
    fontFamily: 'Inter',
    fontWeight: '600',
    lineHeight: '140%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem auto'
  },
  formStyle: {
    marginLeft: '-10%',
    width: '720px',
    height: '365px'
  },
  submitButtonStyle: props => ({
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      height: '44px'
    },
    backgroundColor: props.button_background_color,
    width: '6.625rem',
    height: '3.125rem',
    fontSize: '1.125rem',
    fontFamily: 'roboto',
    fontWeight: '500',
    lineHeight: '165%',
    color: '#fff',
    margin: 'auto',
    marginRight: '0px',
    marginTop: '-1px',
    whiteSpace: 'nowrap',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: '6px',
    borderBottomRightRadius: '6px',
    textTransform: 'capitalize',
    '&:hover': {
      opacity: 0.9,
      backgroundColor: props.button_background_color,
      color: '#fff'
    }
  }),
  submitButtonErrorStyle: {
    backgroundColor: '#FF4D3D !important',
    '&:hover': {
      opacity: 0.9,
      backgroundColor: '#FF4D3D !important'
    }
  }
}));

const ExitIntentPopup = ({ data, state, registerCta, isOpen, handleClose }) => {
  const {
    title,
    subtitle,
    image,
    button_title,
    button_background_color,
    provide_material_immediately,
    tags
  } = data;

  const classes = useStyles({ button_background_color });
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('xs'));

  const {
    register,
    handleSubmit,
    formState: { isDirty }
  } = useForm({
    defaultValues: {
      tags,
      username: ''
    }
  });

  register('tags', { value: tags });

  const { isLoading, isError, data: ctaRegisterData, error } = state;

  // "provide material immediately" mode closes the popup immediately, no need to show anything.
  const isFinishedSubmittingEmail = ctaRegisterData && !provide_material_immediately;

  const emailInput = isFinishedSubmittingEmail ? null : (
    <div
      className={cn(
        classes.inputContainerStyle,
        isError && !isDirty && classes.inputContainerErrorStyle
      )}
    >
      <InputBase
        placeholder="Your Email"
        type="email"
        name="username"
        required
        label="Email"
        className={classes.emailInputRootStyle}
        inputRef={register}
      />
      {isFinishedSubmittingEmail ? (
        <Typography>
          Your free guide will arrive in your email inbox momentarily.
          <br />
          Thanks for visiting Aleph Beta, we hope to see you back soon!
        </Typography>
      ) : (
        <Button
          className={cn(
            classes.submitButtonStyle,
            isError && !isDirty && classes.submitButtonErrorStyle
          )}
          disabled={isLoading}
          disableElevation
          type="submit"
        >
          {button_title}
        </Button>
      )}
    </div>
  );

  const closecolor = isMobileDevice ? '#FFF' : 'rgba(0, 0, 0, 1)';

  return (
    <Dialog
      isOpen={isOpen}
      backdrop="rgba(0,0,0,0.59)"
      className={classes.dialogStyle}
      id="banner_exit_intent"
      disableScrollLock
    >
      <form className={classes.formStyle} onSubmit={handleSubmit(registerCta)}>
        <Box className={classes.boxStyle}>
          <img src={image.url} alt={image.raw.name} className={classes.imageStyle} />
          <Grid item className={classes.gridStyle}>
            <Typography className={classes.titleStyle}>{title}</Typography>
            <Typography className={classes.subtitleStyle}>{subtitle}</Typography>
            {isLoading ? <LoadingSpinner /> : emailInput}
            {isError && !isDirty && (
              <div className={classes.errorText}>
                <span>{error}</span>
              </div>
            )}
          </Grid>
          <ButtonClosePopup
            className={classes.closeButtonStyle}
            color={closecolor}
            iconSize={{ width: '0.87775rem', height: '0.81506rem' }}
            handleClose={handleClose}
          />
        </Box>
      </form>
    </Dialog>
  );
};

ExitIntentPopup.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      raw: PropTypes.shape({ name: PropTypes.string }).isRequired
    }).isRequired,
    button_title: PropTypes.string.isRequired,
    button_background_color: PropTypes.string.isRequired,
    provide_material_immediately: PropTypes.string,
    tags: PropTypes.string
  }).isRequired,
  state: PropTypes.shape({
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    error: PropTypes.string,
    data: PropTypes.shape({})
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  registerCta: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default ExitIntentPopup;
