import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Link from '~components/Link';
import Dialog from '~components/Dialog';

import BFimage from '~images/black-friday/BFImage.png';
import { BLACK_FRIDAY_EXPRESS_CHECKOUT_PATH } from './constants';

const useStyles = makeStyles(theme => ({
  dialogStyle: {
    '& > div > .MuiPaper-root': {
      backgroundColor: 'transparent'
    },
    [theme.breakpoints.down('xs')]: {
      '& > div > .MuiDialog-paperWidthSm': {
        maxWidth: 'calc(100% - 24px) '
      }
    }
  },
  boxStyle: {
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      height: '267.5px',
      marginRight: 'auto',
      marginLeft: 'auto'
    },
    backgroundColor: '#E6F0FF',
    height: '454.75px',
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'center'
  },
  stripeStyle: {
    [theme.breakpoints.down('xs')]: {
      height: '70px',
      padding: '11.76px 21.76px'
    },
    width: '100%',
    height: '119px',
    position: 'absolute',
    bottom: '0px',
    backgroundColor: '#1775FB',
    borderRadius: '0px 0px 16px 16px',
    padding: '20px 37px',
    boxShadow: '0px 3.2px 2px #808080'
  },
  imgStyle: {
    [theme.breakpoints.down('xs')]: {
      width: '237.3px',
      height: '152px',
      marginTop: '22.74px'
    },
    width: '403.42px',
    height: '258.4px',
    marginTop: '38.66px'
  },
  largeText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '21px',
      lineHeight: '29px'
    },
    fontSize: '35px',
    lineHeight: '49px',
    fontWeight: '700',
    color: '#FFFFFF'
  },
  smallText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      lineHeight: '13px'
    },
    fontSize: '14px',
    lineHeight: '22.4px',
    fontWeight: '700',
    color: '#FFFFFF'
  },
  buttonStyle: {
    [theme.breakpoints.down('xs')]: {
      top: '13px',
      right: '23.53px',
      fontSize: '11px',
      lineHeight: '17.5px'
    },
    font: 'Roboto',
    fontSize: '18px',
    lineHeight: '29.7px',
    fontWeight: '600',
    color: '#000000',
    backgroundColor: '#FFFFFF',
    border: 'solid black 2px',
    borderRadius: '5.87px',
    textTransform: 'none',
    position: 'absolute',
    top: '22px',
    right: '40px',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  closeButtonStyle: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    borderRadius: '50%'
  },
  closeIconStyles: {
    fontSize: '2rem',
    color: '#000000'
  }
}));

const BFExitIntentPopup = ({ isOpen, handleClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      isOpen={isOpen}
      backdrop="rgba(0,0,0,0.59)"
      className={classes.dialogStyle}
      id="banner_exit_intent"
      disableScrollLock
    >
      <Box className={classes.boxStyle}>
        <img src={BFimage} className={classes.imgStyle} alt="" />
        <Box className={classes.stripeStyle}>
          <Typography className={classes.largeText}>DEAL OF THE YEAR!</Typography>
          <Typography className={classes.smallText}>
            Get a premium subscription for $3 for 3 months.
          </Typography>
          <Button
            className={classes.buttonStyle}
            component={Link}
            to={BLACK_FRIDAY_EXPRESS_CHECKOUT_PATH}
            onClick={handleClose}
          >
            Redeem Offer
          </Button>
        </Box>
        <IconButton aria-label="close" className={classes.closeButtonStyle} onClick={handleClose}>
          <CloseIcon className={classes.closeIconStyles} />
        </IconButton>
      </Box>
    </Dialog>
  );
};

BFExitIntentPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default BFExitIntentPopup;
